import React from "react";
import { Link } from "react-router-dom";

function Navigation() {
    return (
      <>
        <nav style={{
          fontWeight:400,
          fontFamily: '"Yantramanav", sans-serif',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: '20px'
          }}>
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/reads">Read</Link>
        </nav>
      </>
    );
  }
  
  export default Navigation;