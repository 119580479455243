import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./footer";
import Navigation from "./nav";
import Socials from "./socials";

// TODO can create the generic format
function Layout({ children }: {children: any}) {
    return (
            <>
            <div style={{
                 margin: "auto",
                 minHeight: '65vh',
                 display: 'flex',
                 flexDirection: 'column',
                 alignItems: 'center',
                 justifyContent: 'center',
                 }}>
                {children ?? <Outlet />}
            </div>
            <div style={{
                width: '40%',
                margin: 'auto',
                fontFamily: '"Yantramanav", sans-serif',
                fontWeight: '400',
                display: 'flex',
                justifyContent: 'center',
            }}>
                <Navigation />
                </div>
                <div style={{
                width: '40%',
                margin: 'auto',
                fontFamily: '"Yantramanav", sans-serif',
                fontWeight: '400',
                display: 'flex',
                justifyContent: 'center',
            }}>
                <Socials />
            </div>
            <div style={{ 
                marginTop: "auto",
                display: "flex",
                alignSelf: "flex-end",
                flexDirection: "column",
                flex: "1",
                }}>
                <Footer />
            </div>
            </>
    );
}

export default Layout;
