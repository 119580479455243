import React from "react";

function ErrorPage() {
    return (
        <>
        <div style={{
            width: '40%', 
            margin: 'auto',
            fontFamily: '"Yantramanav", sans-serif',
            fontWeight: '400'
            }}>
            <h1>404, page doesn't exist.</h1>
            <p>
                We couldn't find the page that you were looking for.
            </p>
        </div>
        
        </>
    );
}

export default ErrorPage;