import React from "react";
import { Link, Route } from "react-router-dom";
import Review from "./review";

function Reads() {
    return (
        <>
            <div style={{
                width: '40%',
                margin: 'auto',
                fontFamily: '"Yantramanav", sans-serif',
                fontWeight: '400'
            }}>
                <h1>Good Reads</h1>
                <li>
                    <Link to="/reads/100-go-mistakes-and-how-to-avoid-them">100 Go Mistakes and How to Avoid Them</Link> by Teiva Harsanyi
                </li>
                <li>
                    <Link to="/reads/the-software-engineers-guidebook">The Software Engineer's Guidebook</Link> by Gergely Orosz
                </li>                
                <li>What's Your Problem?: To Solve Your Toughest Problems, Change the Problems You Solve. by Thomas Wedell-Wedellsborg</li>
                <li>
                    Turn The Ship Around!: A True Story of Building Leaders by Breaking the Rules by L. David Marquet (Author), Stephen R Covey (Foreword)
                </li>
            </div>

        </>
    );
}

export default Reads;

