import React, { useEffect, useState } from 'react'
import Markdown from 'react-markdown'
import { useParams } from 'react-router-dom';


function Review() {
    const [content, setContent] = useState("");
    let { readID } = useParams();

    // TODO not sure if need to use a react hook here
    useEffect(() => {
        fetch(`/reviews/${readID}.md`)
          .then((res) => res.text())
          .then((text) => setContent(text));
      }, []);
    return (
        <>
        <div style={{
                width: '40%',
                margin: 'auto',
                fontFamily: '"Yantramanav", sans-serif',
                fontWeight: '400'
            }}>
                 <Markdown>{content}</Markdown>
            </div>
        </>
       
    )
}

    
export default Review;