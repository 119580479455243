import React from "react";
import Wave from "react-wavify";
import styled from "@emotion/styled";


const WaveContainer = styled.div`
  // position: fixed;
  width: max-content;
  // left: 0;
  // right: 0;
  // bottom: 0;
  height: '90vh'};
  display: flex;
  // z-index: -1;
  // transform: rotate(180deg);
`;

function Footer() {
  return (
    <Wave
    fill="#000000"
    paused={false}
    opacity="1"
    style={{display: 'flex', height: '20vh'}}
    options={{
      height: 0,
      amplitude: 50,
      speed: 0.1,
      points: 3,
    }}
   />
  );
}

export default Footer;
