import React from "react";
import Socials from '../socials';
import {
  Link,
  Route,
  Routes,
} from "react-router-dom";
import About from "./about";


function Introduction() {
  return (
    <>
      <div style={{
        width: '40%', 
        margin: 'auto',
        fontFamily: '"Yantramanav", sans-serif',
        fontWeight: '400',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        }}>
              
      <h1
        style={{
          fontFamily: '"Yantramanav", sans-serif',
          fontSize: '42pt',
          margin: '0',
        }}
      >
        Hello. I'm Jay.
      </h1>

      <div>
      <h2
          style={{
            fontFamily: '"Yantramanav", sans-serif',
            fontWeight: '300'
          }}
        >
          I enjoy building <b style={{ fontWeight: '500' }}>Products</b>
        </h2>

        <h2
          style={{
            fontFamily: '"Yantramanav", sans-serif',
            fontWeight: '300'
          }}
        >
          <b style={{ fontWeight: '500' }}>Welcome</b> to my webpage
        </h2>


      </div>
      </div>
      </>
  );
}

export default Introduction;
