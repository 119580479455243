import React from 'react';
import './App.css';
import Layout from './components/layout';


// <-- uses Outlet https://stackoverflow.com/questions/76385016/react-router-how-can-i-reuse-my-layout-for-the-errorelement-in-the-root-route
const App = () => (
  <Layout children={undefined} />
);

export default App;
