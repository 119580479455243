import React, { Children } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Introduction from './components/pages/introduction';
import ErrorPage from './components/pages/error';
import Layout from './components/layout';
import About from './components/pages/about';
import Reads from './components/pages/read';
import Review from './components/pages/review';

const router = createBrowserRouter([
  {
    path: "/",
    element:<App/>, 
    errorElement: (<Layout><ErrorPage /></Layout>), 
    children: [
      {
        index: true,
        element: <Introduction />
      },
      {
        path: "/about",
        element: <About />
      },
      {
        path: "/reads",
        element: <Reads />
      },
      {
        path: "/reads/:readID",
        element: <Review />
      }
    ]
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* <App /> */}
    <RouterProvider router={router} />
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
