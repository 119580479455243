import React from "react";

function About() {
    return (
        <>
        <div style={{
            width: '40%', 
            margin: 'auto',
            fontFamily: '"Yantramanav", sans-serif',
            fontWeight: '400'
            }}>
            <h1>About Myself</h1>
            <p>
                The success of well designed software products brings me joy. This interest led me to my current role as a backend engineer. In my role, I build API services for end user applications. 
            </p>
            <p>
                I believe that deep technical expertise makes a difference when building high quality services. To refine my craft, I'm pursuing a computing masters with Georgia Technology. (Graduating Fall 2023)
            </p>
        </div>
        
        </>
    );
}

export default About;