import React from "react";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaGithub } from "@react-icons/all-files/fa/FaGithub";

function Socials() {
  return (
    <>
      <a href={"https://github.com/jwaycube"}>
        <FaGithub className="fa-flag" size={40} />
      </a>
      <a href={"https://www.linkedin.com/in/jwayjinjun/"}>
        <FaLinkedin className="fa-flag" size={40} />
      </a>
    </>
  );
}

export default Socials;
